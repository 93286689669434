import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Root = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  /* animation-duration: 150ms; */
  /* animation-timing-function: ease-out; */
  /* animation-name: ${fadeIn}; */
  outline: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 7000;

  @media (max-width: 1023px) {
    padding: 70px 24px 35px;
  }
`;

export const Dialog = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  pointer-events: none;

  @media (max-width: 1023px) {
    align-items: stretch;
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: calc(100% - 48px);
    max-width: ${p => p.$maxWidth};
    height: calc(100% - 128px);
    margin-top: 64px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: #202020;
  background-clip: padding-box;
  outline: 0;
  pointer-events: all;

  @media (max-width: 1023px) {
    padding: 90px 24px 65px;
  }

  @media (min-width: 1024px) {
    padding: 87px 95px 80px;
  }
`;

export const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #171717;
  border-radius: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1023px) {
    width: 110px;
    height: 110px;
    padding: 10px;
  }

  @media (min-width: 1024px) {
    width: 140px;
    height: 140px;
    padding: 12px;
  }
`;

export const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 0;
  color: #fff;
  background-color: transparent;
  text-align: center;
  border: none;
  transition: color 150ms;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 1023px) {
    top: -64px;
    right: -8px;
    font-size: 15px;
  }

  @media (min-width: 1024px) {
    top: 32px;
    right: 32px;
    font-size: 24px;
  }
`;

export const Body = styled.div`
  margin: -8px -16px;
  padding: 8px 16px;
  overflow: hidden auto;
`;
