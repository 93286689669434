import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

import { Root, Dialog, Content, Logo, Close, Body } from "./Modal.style";

import logoSrc from "../../images/logo-circle.svg";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const Modal = ({
  onClose,
  closeOnClickBackground,
  closeOnEsc,
  children,
  maxWidth,
}) => {
  const rootEl = useRef(null);
  useLockBodyScroll();

  const handleMouseDismiss = e => {
    if (e.target === rootEl.current) onClose();
  };

  const handleEscDismiss = event => {
    if (event.keyCode === 27 && onClose) onClose();
  };

  useEffect(() => {
    if (!closeOnEsc) return undefined;
    window.addEventListener("keydown", handleEscDismiss);

    return () => {
      window.removeEventListener("keydown", handleEscDismiss);
    };
  });

  return (
    <Portal>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        ref={rootEl}
        onClick={onClose && closeOnClickBackground ? handleMouseDismiss : null}
      >
        <Dialog $maxWidth={maxWidth}>
          <Content>
            {onClose && (
              <Close type="button" onClick={onClose}>
                <svg
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 26 26"
                >
                  <path
                    fill="currentColor"
                    d="M23.888 26 13 15.112 2.112 26 .001 23.889l10.888-10.888L0 2.112 2.112 0l10.889 10.889L23.889.001 26 2.112 15.112 13 26 23.888 23.888 26Z"
                  />
                </svg>
              </Close>
            )}

            <Logo>
              <img src={logoSrc} alt="" />
            </Logo>

            <Body>{children}</Body>
          </Content>
        </Dialog>
      </Root>
    </Portal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  closeOnClickBackground: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
};

Modal.defaultProps = {
  onClose: null,
  closeOnClickBackground: true,
  closeOnEsc: true,
  maxWidth: null,
};

export default Modal;
