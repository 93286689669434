import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm, Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";

import {
  Root,
  Subtitle,
  Title,
  Form,
  Fields,
  Field,
  Input,
  SelectContainer,
  Select,
  Agree,
  Partners,
  Checkbox,
  PartnersText,
  PartnersLogos,
  PartnersLink,
  Submit,
  SubmitIcon,
  Rules,
  Preloader,
  SuccessSubtitle,
  SuccessMsg,
  SuccessNote,
} from "./ModalEntry.style";

import logoNashvilleSrc from "../../images/logo-nashville.svg";
// import logoHolidaySrc from "../../images/logo-holiday.jpeg";

import { birthValidation, phoneValidation } from "./validation";
import Modal from "../Modal/Modal";

const ModalEntry = ({ initialEmail, onClose, onRulesShow }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formMessage, setFormMessage] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: initialEmail,
      agree: false,
    },
  });

  const onSubmit = async data => {
    if (!executeRecaptcha) {
      console.log("Invalid reCAPTCHA. Please try again.");
      setFormMessage({
        type: "error",
        message: "Invalid reCAPTCHA. Please refresh page and try again.",
      });
      return;
    }
    setIsSubmitting(true);
    const captcha = await executeRecaptcha("entry");
    setFormMessage(null);
    fetch("https://api.olesmoky.com/nmd", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      mode: "cors",
      body: JSON.stringify({ ...data, captcha }),
    })
      .then(response => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then(() => {
        setFormMessage({
          type: "success",
          message:
            "You successfully entered the National Moonshine Day sweepstakes!",
        });
        reset();
      })
      .catch(err => {
        console.error(err);
        setFormMessage({
          type: "error",
          message: "An error occurred.  Please try again.",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal
      onClose={onClose}
      // closeOnClickBackground={false}
      closeOnEsc
      // closeOutside
      closeOffset="16px"
      closeIconSize="24px"
      customClose={
        <svg
          viewBox="0 0 195 195"
          width="1em"
          height="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeWidth="15"
            d="M8 186 186 8M8 8l179 179"
            stroke="#6D5932"
            fill="none"
          />
        </svg>
      }
      maxWidth="800px"
    >
      <Root>
        {formMessage?.type === "success" ? (
          <>
            <SuccessSubtitle>OFFICIAL ENTRY FORM</SuccessSubtitle>
            <SuccessMsg>
              Thanks <span>for entering our National Moonshine Day</span>{" "}
              Sweepstakes!
            </SuccessMsg>
            <SuccessNote>
              Check your email for info on how you can enjoy{" "}
              <span>15% OFF</span> your next purchase with Ole Smoky!
            </SuccessNote>
          </>
        ) : (
          <>
            <Subtitle>OFFICIAL ENTRY FORM</Subtitle>
            <Title>National moonshine day</Title>

            <Form onSubmit={handleSubmit(onSubmit)}>
              {formMessage && (
                <p
                  style={{
                    marginBottom: "40px",
                    color: formMessage.type === "error" ? "red" : "green",
                  }}
                >
                  {formMessage.message}
                </p>
              )}
              <Fields>
                <Input
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape
                    },
                  })}
                  name="email"
                  type="email"
                  placeholder="EMAIL ADDRESS"
                  className={errors.email && "--error"}
                />
                <Controller
                  name="birth"
                  control={control}
                  rules={{
                    required: true,
                    validate: birthValidation,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      as={MaskedInput}
                      value={value}
                      onChange={onChange}
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      keepCharPositions
                      //guide
                      placeholder="DATE OF BIRTH (MM/DD/YYYY)"
                      className={errors.birth && "--error"}
                    />
                  )}
                />
                <Input
                  {...register("firstName", { required: true, minLength: 2 })}
                  name="firstName"
                  placeholder="FIRST NAME"
                  className={errors.firstName && "--error"}
                />
                <Input
                  {...register("lastName", { required: true, minLength: 2 })}
                  name="lastName"
                  placeholder="LAST NAME"
                  className={errors.lastName && "--error"}
                />
              </Fields>

              <Field>
                <Input
                  {...register("address", { required: true })}
                  name="address"
                  placeholder="STREET ADDRESS"
                  className={errors.address && "--error"}
                />
              </Field>

              <Fields>
                <Input
                  {...register("city", { required: true })}
                  name="city"
                  placeholder="CITY"
                  className={errors.city && "--error"}
                />
                <Input
                  {...register("state", { required: true })}
                  name="state"
                  placeholder="STATE"
                  className={errors.state && "--error"}
                />
                <Input
                  {...register("postal", {
                    required: true,
                    minLength: 5,
                    maxLength: 10,
                  })}
                  name="postal"
                  placeholder="POSTAL CODE"
                  className={errors.postal && "--error"}
                />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: true,
                    validate: phoneValidation,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      as={MaskedInput}
                      value={value}
                      onChange={onChange}
                      mask={[
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      keepCharPositions={false}
                      placeholder="PHONE"
                      className={errors.phone && "--error"}
                    />
                  )}
                />
              </Fields>
              <Field>
                <SelectContainer>
                  <Select
                    {...register("learn", { required: true })}
                    name="learn"
                    className={errors.learn && "--error"}
                  >
                    <option value="">
                      How did you learn about this promotion?
                    </option>
                    <option value="website">Website</option>
                    <option value="distillery">Distillery</option>
                    <option value="festival">Festival</option>
                    <option value="liquor_store">Liquor Store</option>
                    <option value="grocery_store">Grocery Store</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="restaurant_bar">Restaurant/Bar</option>
                  </Select>
                </SelectContainer>
              </Field>

              <Agree>
                <Checkbox {...register("agree")} name="agree" type="checkbox" />
                <span>
                  Check here if you would like to receive special information
                  and offers from Ole Smoky.
                </span>
              </Agree>

              <Partners>
                <Checkbox
                  {...register("partners")}
                  name="partners"
                  type="checkbox"
                />
                <PartnersText>
                  <div>
                    Check here if you would like to receive special information
                    and offers from our promotion partner, Visit Music City.
                  </div>
                  <PartnersLogos>
                    <PartnersLink
                      href="https://www.visitmusiccity.com/"
                      target="_blank"
                    >
                      <img src={logoNashvilleSrc} alt="" />
                    </PartnersLink>
                  </PartnersLogos>
                </PartnersText>
              </Partners>

              <Submit
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length}
              >
                <span>Enter Now</span>
                {isSubmitting ? <Preloader /> : <SubmitIcon />}
              </Submit>

              <Rules>
                Click here to see the&nbsp;
                <button type="button" onClick={onRulesShow}>
                  official rules
                </button>
              </Rules>
            </Form>
          </>
        )}
      </Root>
    </Modal>
  );
};

ModalEntry.propTypes = {
  initialEmail: PropTypes.string,
  onClose: PropTypes.func,
  onRulesShow: PropTypes.func,
};

ModalEntry.defaultProps = {
  initialEmail: "",
  onClose: null,
  onRulesShow: null,
};

export default ModalEntry;
